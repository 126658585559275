var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "exchange-rate-wrapper" },
    [
      _vm.exchangeRates
        ? [
            _c("p", { staticClass: "exchange-base-currency" }, [
              _vm._v(
                "Value of 1 " +
                  _vm._s(_vm.newInputCurrency || _vm.inputCurrency)
              ),
            ]),
            _vm.newInputCurrency
              ? _c(
                  "p",
                  {
                    staticClass: "reset",
                    on: {
                      click: function ($event) {
                        return _vm.updateInputCurrency(_vm.inputCurrency)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " ⇦ Reset back to " + _vm._s(_vm.inputCurrency) + " "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._l(_vm.exchangeRates, function (exchange, index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.tooltip(_vm.makeInverse(exchange)),
                      expression: "tooltip(makeInverse(exchange))",
                    },
                  ],
                  key: index,
                  staticClass: "exchange-rate-row",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "country",
                      on: {
                        click: function ($event) {
                          return _vm.updateInputCurrency(exchange.currency)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "flag",
                        attrs: {
                          src: _vm._f("flagUrl")(exchange.currency),
                          alt: "Flag",
                        },
                      }),
                      _vm._v(" " + _vm._s(exchange.currency) + " "),
                    ]
                  ),
                  _c("p", { staticClass: "value" }, [
                    _c("span", { staticClass: "input-currency" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("applySymbol")(
                              1,
                              _vm.newInputCurrency || _vm.inputCurrency
                            )
                          ) +
                          " = "
                      ),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("applySymbol")(
                            exchange.value,
                            exchange.currency
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              )
            }),
            _c("p", { staticClass: "last-updated" }, [
              _vm._v("Updated on " + _vm._s(_vm.lastUpdated)),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }